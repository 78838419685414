import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { BiCalendar, BiCheckCircle, BiGlobe } from "react-icons/bi";
import { lbl_date, title_case ,format_date_tbl,lbl_amt} from '../../../helpers/common_helper';
import {daywiseSwitchReport} from '../../../actions/report_actions';
import CommonReactTable from "../../../helpers/commonReact_table";
import { BasicReactTable } from '../../../helpers/react_table'
import {get} from "../../../helpers/storage_helper";
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getFloatSwitchAccounts, getRefAccounts } from '../../../actions/account_txn_actions';
import {FaLongArrowAltRight} from 'react-icons/fa';
import Toggle from '../../common/component/Toggle';
import { dd_value } from '../../../actions/common_actions';
class SwitchReportsContainer extends FlowComponent {

    constructor(props) {
        super(props);
        this.state = {
            active_tab:"month_wise",
            account_from:{},
            accounts:[],
            selected_options:null,
            report_data: null,
            currency_code : this.currency_code,
            channel : null,
            dateWiseFilter : false,
            rm_id : null,
            switch_type: null
        };
       
    }

    componentWillMount() {
      this.get_day_wise_switches();
      this.loadAccounts();
      this.load_flow_rel_mgr();
    }

    reset_all_filters(){
      this.setState({from_date:null,to_date:null,selected_options:null,switchData:[],report_data : null,channel : "" , rm_id : "" })
      this.load_flow_rel_mgr();
    }

    float_switch_accounts_options(accounts){
        var arr = [];
        var idCounter = 1;
        if (accounts.length > 0) {
          for (var i = 0; i < accounts.length; i++) {
            for (var j = 0; j < accounts.length; j++) {
              if (accounts[i] !== accounts[j]) {
                var obj = {
                  id: idCounter++,
                  from: accounts[i],
                  to: accounts[j],
                };
               
                arr.push(obj);
              }
            }
          }
        }
        
    this.setState({switch_acc_options:arr})        
    }

    loadAccounts = () => {
		var account = {"country_code": this.country_code};    
		  getFloatSwitchAccounts(this.req({account},true))
			.then((response) => {
			this.resp(response)
			  if(!response){return };
			  if(response.status === "success"){
				if(response.data != ""){
				  this.setState({accounts:response.data.acc_prvdrs})
                  this.float_switch_accounts_options(response.data.acc_prvdrs)

				}     
			  }else{ 
				alert(response.message + "\n" + JSON.stringify(response.data));  
			  }
			}
		  );

	  }


    get_input_handler(value,type){
        this.setState({[type]:value})
    }
    get_day_wise_switches(switch_acc_prvdr_fsearch=null){

        
        this.setState({loader:true})

        var req={start_date:  this.state.from_date ? (this.state.active_tab=="month_wise") ? moment(this.state.from_date).format("YYYYMM")  :this.state.from_date : (this.state.active_tab=="month_wise")?moment( new Date()).format("YYYYMM"): moment( new Date()).format("YYYY-MM-DD") ,
        end_date : this.state.to_date ? (this.state.active_tab=="month_wise")?moment(this.state.to_date).format("YYYYMM") : this.state.to_date : (this.state.active_tab=="month_wise")?moment( new Date()).format("YYYYMM"):  moment( new Date()).format("YYYY-MM-DD") ,report_type :this.state.active_tab }

        if(this.state.channel != null && this.state.channel != ""){
          req.channel = this.state.channel;
        }

        if(this.state.switch_type != null && this.state.switch_type != ""){
          req.switch_type = this.state.switch_type;
        }

        if(this.state.rm_id != null && this.state.rm_id != ""){
          req.rm_id = this.state.rm_id;
        }
        if(switch_acc_prvdr_fsearch!=null || (this.state.selected_options) ){
            req["from_acc_prvdr"]=this.state.selected_options.from
            req["to_acc_prvdr"]= this.state.selected_options.to
        }
        daywiseSwitchReport(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status=="success"){
                this.setState({switchData: response.data.switch_report,report_data : response.data.revene_report,  column_data:this.column_data(), no_data:true, loader:false})
            }
        })
    }
 
    column_data = () => {
        return [
            
      
           {
              Header : <div >{(this.state.active_tab=="day_wise") ? "Switched Date" : "Switched Month" }</div>,
              id: "delivery_dates",
              accessor: "delivery_date",   
              Cell : cell => {return <>{(this.state.active_tab=="day_wise")? format_date_tbl(cell.row.original.date_field) : 
              moment(cell.row.original.date_field, "YYYYMMDD").format("MMM YYYY") }</>}  
              
             
          },
          {
            
            Header : <div className='text-center'>No of Switches</div>,
            id: "switch_counts",
            accessor: "switch_count",   
            Cell : cell =>{return <div className='text-center'>{cell.row.original.switch_count}</div>}
            
          },
          {
            Header :<div className='text-center'>No of Customers</div> ,
            id: "cust_counts",
            accessor: "cust_count",   
            Cell : cell => {return <> <p className=' m-0 text-center' >{cell.row.original.cust_count}</p>  </>}    
            
          },
          {
            Header :<div className='text-end'>Switch Amount</div> ,
            id: "product_amts",
            accessor: "product_amt",   
            Cell : cell => {return <> <p className=' m-0 text-right' >{this.formatNumber(cell.row.original.product_amt)}</p>  </>}    
            
          },
          {

            Header : <div className='text-end'>Fee Amount</div>,

            id: "product_fees",
            accessor: "product_fee",   
            Cell : cell => {return <> <p className=' m-0 text-right' >{this.formatNumber(cell.row.original.product_fee)}</p>  </>}    
            
          },
          {
            Header :<div className='text-end'>Transaction Amount</div> ,
              id: "transfer_amts",
              accessor: "transfer_amt",   
              Cell : cell => {return <> <p className=' m-0 text-right' >{this.formatNumber(cell.row.original.transfer_amt)}</p>  </>}    
              
          },
          {
            Header :<div className='text-end'>Success Rate</div> ,
              id: "success_rate",
              accessor: "success_rate",   
              Cell : cell => {return <> <p className=' m-0 text-right' >{cell.row.original.success_rate} %</p>  </>}    
              
          }
  ]
      
    }
    tabHandler(type){
        this.setState({active_tab:type},()=>{this.get_day_wise_switches()})
    }

    handlePurposeChange = (selected_options,id) =>{
		this.setMultiselectOptionOnState(selected_options,id,this.state.account_from)

	}

    get_switch_accounts_options(accounts){
        this.setState({selected_options:accounts},()=>{ this.get_day_wise_switches("acc_provider_filteration")})

    }

    formatNumber(n, decimal = 2) {
      var ranges = [
        { divider: 1e9 , suffix: 'B' },
        { divider: 1e6 , suffix: 'M' },
        { divider: 1e3 , suffix: 'K' }	
      ];
  
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          let format_num = (n / ranges[i].divider).toFixed(decimal) + " " + ranges[i].suffix
          
          return  (
              <>
                  {format_num} <span style={{ fontWeight: 'normal' }}>{this.state.currency_code}</span>
              </>
          );
         
        }
      }

      return( 
          <>{Number(n).toFixed(0)} <span style={{ fontWeight: 'normal' }}>{this.state.currency_code}</span></>
      );
  
    }
   
    render() {
        return (
            <div>
              
                <h2 className='text-white mb-4 ml-4 mt-5' > Float Switch Reports</h2>
                  <div className={``}>
                    <div className='p-5 churn_report_card'>

                        <div className='col-md-6 col-lg-3 col-sm-12 mb-3 ' style={{alignSelf:'end'}}>
                          <div className='d-flex align-items-center justify-content-start row'>
                            <div className='d-flex align-items-center col-lg-9 col-md-6  px-0 py-0 date_toggle' style={{background:"#252d41",color:'white',alignSelf:'end'}} >
                              <p className={` my-0 col-6 py-3 p-2 text-center pointer ${(this.state.active_tab=="day_wise") ? "cur_tab":''}`} style={{fontSize: "14px"}} onClick={()=>{this.tabHandler("day_wise")}}>Day Wise</p>

                              <p className={`  my-0 p-2 py-3 col-6 text-center pointer ${(this.state.active_tab=="month_wise") ? "cur_tab":''}`} style={{fontSize: "14px"}}  onClick={()=>{this.tabHandler("month_wise")}}>Month Wise</p>
                            </div>

                          </div>
                        </div>

                        <div className="row px-2" >
                            <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                                <label className='col p-0 my-3 text-white'>Initiated From<font className="text-danger"> * </font></label>
                                <i className='material-icons m-0 pe-auto pointer' style={{position: 'absolute',color:'white', top: '50%', transform: 'translateY(-50%)', right: '10px'}}>calendar_month</i>
                          
                                <DatePicker 
                                  closeOnSelect={true} 
                                  isValidDate={(current) => {
                                  const today = moment().startOf('day');
                                  return current.isSameOrBefore(today, 'day');  }} 
                                  value={ this.state.from_date ? (this.state.active_tab=="day_wise") ? moment(this.state.from_date).format('DD MMM YYYY'): moment(this.state.from_date).format('MMMM YYYY'):''}  
                                  onChange={(value)=>this.get_input_handler(  moment(value).format("YYYY-MM-DD"),"from_date")}  
                                  inputProps={{ id: 'switch_report_start_date',placeholder: (this.state.active_tab=="month_wise") ? 'Start Month' : 'Start date', name: 'Referral_from',autoComplete:'off'}} 
                                  dateFormat= { (this.state.active_tab=="month_wise") ? "MMMM yyyy" : "DD MM YYYY" }
                                  showMonthYearPicker timeFormat={false} 
                                  required="required" 
                                  onKeyDown={(value) => value.preventDefault()}
                                />
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                                <label className='col p-0 my-3'>Initiated To<font className="text-danger"> * </font></label>
                                <i className='material-icons m-0 pointer' style={{position: 'absolute',color:'white', top: '50%', transform: 'translateY(-50%)', right: '10px'}}>calendar_month</i>
                                <DatePicker closeOnSelect={true} 
                                  isValidDate={(current) => {
                                  const today = moment().startOf('day');
                                  return current.isSameOrBefore(today, 'day');  }} 
                                  value={ this.state.to_date ? (this.state.active_tab=="day_wise") ? moment(this.state.to_date).format('DD MMM YYYY'): moment(this.state.to_date).format('MMMM YYYY'):''}  
                                  onChange={(value)=>this.get_input_handler(  moment(value).format("YYYY-MM-DD"),"to_date")}  
                                  inputProps={{ id: 'switch_report_end_date', placeholder: (this.state.active_tab=="month_wise") ? 'End Month' : 'End date', name: 'Referral_from',autoComplete:'off'}} 
                                  dateFormat= { (this.state.active_tab == "month_wise") ?"MMMM yyyy" : "DD MM YYYY" }
                                  showMonthYearPicker timeFormat={false} 
                                  required="required" 
                                  onKeyDown={(value) => value.preventDefault()}/>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3 p-3 ' style={{width:'22%'}}>
                              <div className='pt-3 switch_report_dd'>
                                  <label className='col p-0 m-0 partial-input-width'  >Switch Direction</label>

                                  <div class="dropdown float_switch_chevron mt-1">
                                      <div class="dropdown-toggle pt-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                          <div className="d-flex pb-2">
                                              
                                              <div className="flex-grow-1 px-1" onClick={()=>this.setState({menuIsOpen : !this.state.menuIsOpen})}>
                                                  {this.state.selected_options ?
                                                      <div className="d-flex align-items-center">
                                                          <div className="d-flex align-items-center" >
                                                              <img src={`/img/${this.state.selected_options.from}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                                              <p className="text-white m-0 px-2" style={{fontSize:'13px'}}>{dd_value(this.state.selected_options.from,'provider_list')}</p>
                                                          </div> 
                                                          <p className="text-white m-0 px-2">-</p>
                                                          <div className="d-flex align-items-center" >
                                                              <img src={`/img/${this.state.selected_options.to}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                                              <p className="text-white m-0 px-2" style={{fontSize:'13px'}}>{dd_value(this.state.selected_options.to,'provider_list')}</p>
                                                          </div> 
                                                      </div>
                                                  : 
                                                      <p className='text-white m-0 pt-2' style={{fontSize:'13px'}}>choose Accounts</p>
                                                  }
                                              </div>

                                              <span><i className="fa-solid fa-chevron-down text-white pr-1"></i></span>
                                          </div>
                                          
                                      </div>

                                      <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                          {this.state.switch_acc_options && this.state.switch_acc_options.map((items,idx)=>{
                                                  return(
                                                      <li onClick={()=>{this.get_switch_accounts_options(items)}}>
                                                          <div key={items.id} className="dropdown-item justify-content-evenly" >
                                                              <img src={`/img/${items.from}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                                              <p className='m-0 text-white px-3' style={{fontSize:'20px'}}><FaLongArrowAltRight style={{}}/></p>
                                                              <img src={`/img/${items.to}_logo_color.png`} className='choosen_float_switch_acc_prvdr' />
                                                          </div>
                                                      </li>
                                                  )}
                                          )}
                                      </ul>
                                  
                                  </div>
                              </div>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                                <div className='pt-3 switch_report_dd'>
                                    <label className='col p-0 partial-input-width'  >RM Name</label>
                                    <select className="form-control p-0" style={{fontSize:'13px'}}  onClick={(e)=>{this.setState({rm_id : e.target.value})}}  id="flow_rel_mgr_id" >
                                    {this.state.flow_rel_mgr_dd} 
                                    </select>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                                <div className='pt-3 switch_report_dd'>
                                    <label className='col p-0 partial-input-width'  >Channel</label>
                                    <select className="form-control p-0" style={{fontSize:'13px'}} value={this.state.channel} onChange={(e) => this.setState({channel:e.target.value})} id="channel" >
                                        <option  value="">choose</option>
                                        <option value="sms">SMS</option>
                                        <option value="ussd">USSD</option>
                                        <option value="cust_app">Customer App</option>
                                        <option value="new_cust_app">New Customer App</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-3 p-3">
                            <div className='pt-3 switch_report_dd'>
                                <label className='product_check_label mt-2'>Switch type</label>
                                <select className="form-control" value={this.state.switch_type} onChange={(e) => this.setState({switch_type:e.target.value})} id="switch_type" >
                                  <option value="">choose</option>
                                  <option value="agent">Agent</option>
                                  <option value="self">Self</option>
                                </select>
                              </div>
                            </div>

                        </div>

                        <div className='row my-3 d-flex align-items-center justify-content-center'>
                    
                          <div className='col-lg-3 mb-4'>
                            <button type="button" class="btn btn-primary py-3 px-4" style={{borderRadius:'5px'}} disabled={this.state.from_date ? false:true} onClick={()=>{this.get_day_wise_switches()}}>search</button>

                            <button type="button" class="btn btn-secondary ml-3 py-3 px-4" style={{borderRadius:'5px'}} disabled={(this.state.from_date || (this.state.to_date) || (this.state.selected_options)) ? false:true} onClick={()=>{this.reset_all_filters()}}>reset</button>

                          </div>

                        </div>

                    </div>

              
                      {this.state.report_data && 
                      <div>
                          <div className="d-flex justify-content-around flex-wrap my-4">
                                  <div className="col-sm-12 col-md-4 mx-2 monthly_report_business_volume balance_border p-4 " style={{backgroundColor:'#32334D',height:'fit-content'}}>
                                      <p className="text-white pb-2 m-0" style={{fontSize:'18px'}}>Total Amount Switched</p>
                                      <p className="text-white m-0" style={{fontSize:'20px',fontWeight:700}}>{this.formatNumber(this.state.report_data.total_amt)}</p>
                                  </div>
                                  <div className="col-sm-12 col-md-4 mx-2 monthly_report_business_volume balance_border p-4 " style={{backgroundColor:'#32334D',height:'fit-content'}}>
                                      <p className="text-white pb-2 m-0" style={{fontSize:'18px'}}>Average Switch Amount</p>
                                      <p className="text-white m-0" style={{fontSize:'20px',fontWeight:700}}>{this.formatNumber(this.state.report_data.avg_amt)}</p>
                                  </div>

                                  <div className="col-sm-12 col-md-4 mx-2 monthly_report_business_volume balance_border p-4 " style={{backgroundColor:'#32334D',height:'fit-content'}}>
                                      <p className="text-white pb-2 m-0" style={{fontSize:'18px'}}>Total Revenue</p>
                                      <p className="text-white m-0" style={{fontSize:'20px',fontWeight:700}}>{this.formatNumber(this.state.report_data.total_fee)}</p>
                                  </div>
                                  <div className="col-sm-12 col-md-4 mx-2 monthly_report_business_volume balance_border p-4 " style={{backgroundColor:'#32334D',height:'fit-content'}}>
                                      <p className="text-white pb-2 m-0" style={{fontSize:'18px'}}>Average Revenue</p>
                                      <p className="text-white m-0" style={{fontSize:'20px',fontWeight:700}}>{this.formatNumber(this.state.report_data.avg_fee)}</p>
                                  </div>
                                  
                          </div>
                      </div>
                      }
                
                      {this.state.loader ? 
                        <div class="d-flex justify-content-center mt-5">
                          <div class="spinner-border text-info" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      :
                      <div className='mt-3'>

                          {this.state.switchData && this.state.switchData.length > 0 ?
                              <div className={`cust_next_follow_up mt-5  }`} >
                                  <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                                    <p className='no-margin text-white'><b>Total Entries : {this.state.switchData.length}</b></p>
                                  </div>

                                  <BasicReactTable row_data={this.state.switchData} column_data={this.state.column_data}     csv_file={true} FName="Switch Reports" pagination={true}  excess_time_action={false} default_page_size={10} loader={this.state.switchData ? false : true}/> 

                                  {/* <CommonReactTable className='border'  exportCSV={true} csvFile={"Switch Reports"} csvData={this.state.switchData} row_data={this.state.switchData}  column_data={this.state.column_data} FName="Switch Reports" global_search={false} showPag={this.state.switchData.length > 9 ? true : false} minRow={0} default_page_size={10} addBackground={true} /> */}

                              </div>
                              :
                              <>
                                  {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}

                              </>
                          }

                      </div>
                      }
                  </div>


            </div>

        )
    }

}




export default SwitchReportsContainer;