import ReactApexChart from 'react-apexcharts';
import FlowComponent from "../../common/component/core/FlowComponent";
import React from 'react';
import moment from "moment";
import { convert_decimal_value } from '../../../helpers/common_helper';
// import { typeOf } from 'react-flags';

class ReportChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLabel: true,
            iconActive: true, 
        };
        // this.updateChartData();
    }
   
    updateChartData = () =>{
        const curr = this.props.curr === undefined ? '' : this.props.curr
        const show_toolbar=this.props.show_toolbar===undefined ? true : this.props.show_toolbar
        const axisBorder=this.props.axisBorder===undefined ? false : this.props.axisBorder
        const grid=this.props.grid===undefined ? true : this.props.grid
        const total_count= this.props.total_fas!=undefined ? this.props.total_fas : undefined
        const roundOff = this.props.roundOff
        const show_count = this.props.show_bar_label
        const bar_labels = this.props.bar_labels
        const founders_chart = this.props.founders_chart
        const y_axis_min = this.props.founders_chart && this.props.y_axis_min ? this.props.y_axis_min : undefined
        const y_axis_max = this.props.founders_chart && this.props.y_axis_max ? this.props.y_axis_max : undefined

        const yAxisConfig = {
            lines: {
                show: true
            },
            min: this.props.founders_chart && this.props.y_axis_min ? this.props.y_axis_min : 0,
            max: this.props.founders_chart && this.props.y_axis_max ? this.props.y_axis_max : undefined,
            forceNiceScale: this.props.founders_chart && this.props.y_axis_min && this.props.y_axis_max ? false : true,
            tickAmount: this.props.founders_chart && this.props.tick_amount ? this.props.tick_amount : undefined,
            stepSize: this.props.tick_amount_step_size != undefined ? this.props.tick_amount_step_size : undefined,
            labels: {
                style: {
                    colors: '#999'
                },
                formatter: function(val) {
                    if (isNaN(val)) return val;
                    if (curr) {
                        return (new Intl.NumberFormat("en-US")).format(convert_decimal_value(val)) + " " + curr;
                    }
                    if (this.props.roundOff) val = Math.floor(val);
                    else val = Math.abs(val).toFixed(2);
                    return (new Intl.NumberFormat("en-US")).format(val) + " " + curr;
                }.bind(this)
            },
            axisBorder: {
                show: axisBorder,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
            title: {
                text: this.props.y_axis_tittle === undefined ? undefined : this.props.y_axis_tittle,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#939394',
                },
            },
        };
    
        const yAxis = this.props.mixed_chart
            ? [
                yAxisConfig,
                {
                    ...yAxisConfig,
                    opposite: true,
                    title: {
                        text: this.props.y_axis_tittle_opposite === undefined ? undefined : this.props.y_axis_tittle_opposite,
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: '#939394',
                        },
                    },
                },
            ]
            : yAxisConfig;

        this.state = {

            series:
            this.props.data,
            iconActive: true,
            options: {
                chart: {
                    width :'100%',
                    height: '80%',
                    stacked : this.props.stacked == undefined ? false : true,
                    zoom: {
                        enabled: show_toolbar
                    },
                    toolbar: {
                        show: show_toolbar,
                        tools:{
                            customIcons: this.props.dataLabel == undefined ?  [
                                {
                                    icon: this.state.iconActive 
                                        ? `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="blue" width="20px" height="20px"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.543 7-.026.062-.055.122-.084.182M2.458 12l.084.182c1.275 4.057 5.065 7 9.543 7 4.478 0 8.268-2.943 9.543-7M2.458 12l.084-.182m19 0c-.03-.06-.059-.12-.084-.182" /></svg>`
                                        : `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="20px" height="20px"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.543 7-.026.062-.055.122-.084.182M2.458 12l.084.182c1.275 4.057 5.065 7 9.543 7 4.478 0 8.268-2.943 9.543-7M2.458 12l.084-.182m19 0c-.03-.06-.059-.12-.084-.182" /></svg>`,
                                    index: -6,
                                    title: this.state.iconActive ? 'Disable Labels' : 'Enable Labels',
                                    class: 'custom-toolbar-icon',
                                    click: () => {
                                        this.handleDataLabelToggle();
                                    }
                                }
                            ]:""
                    }
                    },
                    events: {
                        legendClick: function(chartContext, seriesIndex, config) {
                            if(!founders_chart) return
                            if(founders_chart == true && config.config.series[seriesIndex].name != 'ontime_payment'){
                                if(config.config.series.length <2){
                                    return
                                }
                                return
                            }
                          const chart = chartContext; 
                          setTimeout(() => {
                            let collapsed_arr = chart.legend.w.globals.collapsedSeriesIndices || [];
                    
                            let minValue = '';
                            let maxValue = '';
                            if (config.config.series[seriesIndex].name === 'ontime_payment') {
                                if (collapsed_arr.includes(seriesIndex)) {
                                    minValue = 0;
                                    maxValue = 10;
                                } else {
                                    minValue = y_axis_min;
                                    maxValue = y_axis_max;
                                }
                    
                                // Update the y-axis min and max values
                                chart.updateOptions({
                                    yaxis: {
                                        min: minValue,
                                        max: maxValue
                                    }
                                });
                            }
                        }, 1000); // Delay in milliseconds
                    }
                    
                          
                    
                      
                          
                          
                        }
                      
                      
                },
                colors: this.props.colors ===undefined ? ["#00e396", "#ff3154", "#fadd17"]:this.props.colors,
                plotOptions: {
                    bar: {
                        horizontal:this.props.horizontal === undefined ? false : true,
                        dataLabels: {
                            position: 'top',
                        },
                        columnWidth:  this.props.biz_ops_colunm_width===undefined ? '50%' :this.props.biz_ops_colunm_width ,  
                        distributed:this.props.distributed===undefined ? false:this.props.distributed,
                        
                    }
                },
                dataLabels: {
                    enabled: this.props.dataLabel == undefined ?  (this.state.dataLabel !== undefined ? this.state.dataLabel : (show_toolbar ? true : undefined)) : this.props.dataLabel ,

                    offsetX : this.props.type=="bar" && this.props.horizontal ? 25 : 0,
                    offsetY: this.props.type === "bar" && !this.props.inside_label ? -20 : 0,
                    style: {
                        colors: this.props.dataLabelColor != undefined ? this.props.dataLabelColor : this.props.colors ===undefined ? ["#00e396", "#ff3154", "#fadd17"] :this.props.colors,
                    },
                    formatter: function(val, { seriesIndex, dataPointIndex, w }) {
                      if(isNaN(val))return val;
                        if (curr) {
                            return (new Intl.NumberFormat("en-US")).format(convert_decimal_value(val)) + " " + curr;
                        }
                        if (show_count) {
                            return bar_labels[dataPointIndex];
                        }

                        if (roundOff) val = Math.floor(val)
                        else val = Math.abs(val).toFixed(2)
                        return (new Intl.NumberFormat("en-US")).format(val) +" "+ curr;
                    }
    
                },

                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                title: {
                    text: this.props.title,
                    align: 'center',
                    style:{
                        color : "#999",
                      
                    }
                },
                legend:{
                    show: this.props.legend ===undefined ?  true :this.props.legend ,
                    height : '100',
                    showForSingleSeries: true,
                    horizontalAlign: "center", 
                    
                    
                },
                xaxis: {
                    type: this.props.xaxistype,
                    tickPlacement : this.props.tickPlacement ? "between":"on",
                    min:this.props.horizontal ? 0 : '',
                    forceNiceScale: this.props.horizontal ? true : false,
                    lines:{
                        show: true,
                    },
                    labels:{
                        hideOverlappingLabels: false,
                        style:{
                            colors : '#999',
                            
                        },
                    },
                    title: {
                        text: this.props.x_axis_tittle===undefined ? undefined : this.props.x_axis_tittle,
                        offsetX: 0,
                        offsetY: this.props.x_axis_tittle===undefined ? 0 : 30,
                        style: {

                            color: '#939394',
                        },


                    },
                                        categories:this.props.categories===undefined ? []:this.props.categories
                },
                yaxis: yAxis,
                grid: {
                    show: grid,
                    borderColor: this.props.founders_chart ? '#7a7b81' : '#90A4AE',
                    strokeDashArray:this.props.founders_chart ?  2:0,
                    position: 'back',  
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },  
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },  
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    }
                },
                forecastDataPoints: {
                    count:this.props.dataProjections&&this.props.projectionMonthDiff?this.props.projectionMonthDiff:0,
                    fillOpacity: 0,
                    strokeWidth: 3,
                    dashArray: 7,
                  },
                  ...(this.props.target && {
                    annotations: {
                      yaxis: this.props.target.map((item, index) => {
                        return {
                          y: item.value,
                          strokeDashArray: 0,
                          strokeWidth: 2,
                          borderColor: index === 1 ? '#ff0000' : '#00E396',
                          label: {
                            style: {
                              color: '#fff',
                              background: index === 1 ? '#00E396' : '#ff0000',
                              width: '100%',
                              display: this.props.showTarget ? 'block' : 'none'
                            },
                            text: `${item.title} target: ${item.value}`
                          }
                        };
                      }),
                    }
                  }) || {
                    annotations: {
                      yaxis: [],
                    }}
                
            },


        };
       if(this.props.x_axis_format === undefined) {
              this.state.options.xaxis.labels.formatter = function(val) {               
                    return moment(val).format("MMM Y");  
             }
       }else if(this.props.x_axis_format ==true){
                this.state.options.xaxis.labels.formatter = function(val) {               
                    return moment(val).format("D MMM Y");  
                }
       }else if(this.props.x_axis_format ===false){
        this.state.options.xaxis.labels.formatter = function(val) {               
            return val;  
        }
}


       if(this.props.total_fas != undefined || this.props.total_fas==false  ) {
        this.state.options.dataLabels.formatter = function(val) { 
            if((val/total_count)*100>0) {
                return  ((val/total_count)*100).toFixed(0) +'%';
            }
            else{
                return '0 %';
            }     
        }
        }
      

    }
    handleDataLabelToggle = () => {
        this.setState(prevState => ({
            dataLabel: !prevState.dataLabel,
            iconActive: !prevState.iconActive 
        }));
    }


    render() {
        
        this.updateChartData();
        return (
            (this.state.series && this.state.series.length >0 && this.state.series[0].data && this.state.series[0].data.length >0 ) ?
                <div id={ this.props.show_toolbar ===undefined ? "chart" :"biz_ops_charts"}>
                    <ReactApexChart options={this.state.options} series={this.state.series} type={this.props.type || 'line'} height={this.props.biz_ops_chart_height || 550} />
                </div>
                :<div className='py-5'>
                <p className='text-center text-light'>No Data Found</p>
                </div>
            )
        }
}

export default ReportChart;