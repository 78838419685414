import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { dd_value, get_record_audits} from '../../../actions/common_actions';

import { useEffect } from 'react';
import withRouter from '../../../Router/Withrouter';
import { useParams } from 'react-router-dom';
import { flaggedCustomers, profileEditHistory, viewWatchHistory, get_rekyc_data  } from '../../../actions/borrower_actions';
import { array_to_string, convert_json_to_parse, format_date_tbl, req } from '../../../helpers/common_helper';
import { lbl_amt } from '../../../helpers/product_helper';
import { check_priv, get } from '../../../helpers/storage_helper';
import moment from 'moment';
const CustomerProfileHistory = ({ handle_history_modal }) => {

    const [show_modal, setshow_modal] = useState(true)
    
    const [history_fields, setHistoryFields] = useState(['status'])
    const [active_field, setactive_field] = useState('status')
    const [loader, setloader] = useState(false)
    const [late_repayments, setlate_repayments] = useState({})

    const params = useParams()
    const [history_datas, sethistory_datas] = useState({
        status_history: null,
        watchlist_history: null,
        flag_history: null,
        profile_edit_history: null,
        ReKYC_history: null,
    })
    const flag_fields = [{ label: "raised_by", key: "initiated_by" },
    { label: "raised_on", key: "flagged_date" },
    { label: "re-KYCed_by", key: "rekyced_by" },
    { label: "re-KYCed_on", key: "rekyced_on" },
    { label: "verified_by", key: "verified_by" },
    { label: "verified_on", key: "verified_on" },
    { label: "unflagged_by", key: "unflagged_by" },
    { label: "unflagged_on", key: "unflagged_on" },


    ]

    useEffect(()=>{
        set_history_fields()
    },[])

    useEffect(() => {

        if (active_field == "flag") {
            flaggedHistory()
        } else if (active_field == "watchlist") {
            WatchListHistory()
        } else if (active_field == "profile_edit") {
            profile_edit_history()
        } else if (active_field == "status") {
            get_profile_status()
        } else if (active_field == "ReKYC") {
            get_rekyc_status()
        } 
        

    }, [active_field])


    const set_history_fields = ()=>{
        const fields = [...history_fields];

        if (check_priv('customer', 'watchlist')) {
            fields.push('watchlist');
        }
        if (check_priv('customer', 'flag')) {
            fields.push('flag');
        }
        if (check_priv('customer', 'edit')) {
            fields.push('profile_edit');
        }
        if (check_priv('customer', 'rekyc_history')) {
            fields.push('ReKYC');
        }
        setHistoryFields(fields.filter(Boolean));
    }

    const flaggedHistory = () => {
        setloader(true)

        var reqest = {}

        reqest['criteria_arr'] = { cust_data: params.cust_id, include_unflag: true }
        flaggedCustomers(req(reqest))
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return ""
                }

                if (response.status == "success") {
                    sethistory_datas((prevState) => ({
                        ...prevState,
                        flag_history: response.data,
                    }));
                    setloader(false)

                }
            })
    }


    const get_profile_status = () => {
        setloader(true)

        const request = { "record_code": params.cust_id, "audit_type": "status_change" }
        get_record_audits(req(request))
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return
                };
                if (response.status === "success") {
                    sethistory_datas((prevState) => ({
                        ...prevState,
                        status_history: response.data,

                    }));
                    setloader(false)

                }
            })
    }

    const get_rekyc_status = () => {
        setloader(true)

        const request = { "cust_id": params.cust_id ,"type":"re_kyc"}
        get_rekyc_data(req(request))
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return
                };
                if (response.status === "success") {
                    sethistory_datas((prevState) => ({
                        ...prevState,
                        ReKYC_history: response.data

                    }));
                    setloader(false)

                }
            })
    }

    const WatchListHistory = () => {
        setloader(true)

        var reqest = { cust_id: params.cust_id }
        viewWatchHistory(req(reqest))
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return
                }
                if (response.status == "success") {
                    sethistory_datas((prevState) => ({
                        ...prevState,
                        watchlist_history: response.data,
                    }));
                    setloader(false)

                }
            })
    }

    const profile_edit_history = () => {
        setloader(true)
        const request = { "cust_id": params.cust_id };

        profileEditHistory(req(request))
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return
                }
                if (response.status == "success") {
                    let data = response.data

                    sethistory_datas((prevState) => ({
                        ...prevState,
                        profile_edit_history: response.data,
                    }));
                    setloader(false)

                }
            })
    }

    const show_all_laterepayments = (index) => {
        setlate_repayments((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    }

    const getHistory = (details, index) => {
        var a = [];
        a.push(
            <div class={` w-100 my-4 late_loan_parent `} >

                <div className={`row d-flex align-items-center w-100 m-0 py-4 late_loans_table `}>
                    <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'> Due date </p>
                    <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'>Settled date </p>
                    <p className='m-0 col-4 text-center viewCus_labelVal sizem right_border text-white'> Amount </p>

                </div>
                <div className={`w-100 ${(details && details.length > 6) ? "scroll_table" : ""} `}>

                    <> {details.length > 0 && details.map((item, idx) => {
                        return (
                            <div className={`row d-flex my-3 mx-0 align-items-center`} key={idx}>
                                <p className='text-white p-0 col-4 text-center'>{lbl_amt(item.loan_principal, get('market').currency_code)}</p>
                                <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.due_date)}</p>
                                <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.paid_date)}</p>
                            </div>
                        )
                    })}  </>
                </div>
            </div>
        )
        return a;
    }


    const loader_function = () => {
        return (<div className='d-flex justify-content-center mt-5'>
            <div>
                <p className='text-light mt-3 text-center'>Loading...</p>
                <div className="product_takeup_loader"></div>
            </div>
        </div>)
    }

    const get_reason_from_json = (json) =>{
        let parse_data = convert_json_to_parse(json)
        let reason = parse_data.reason  ? Array.isArray(parse_data.reason) ?  array_to_string(parse_data.reason):dd_value( parse_data.reason, parse_data.reason):"N/A"

        return reason;
        // (convert_json_to_parse(item.data_after)).reason ? dd_value((convert_json_to_parse(item.data_after)).reason[0],'') : ""
    }



    return (
        <div>
            <Modal
                backdrop="static"
                className='txn_modal edit_history_model' show={show_modal} onHide={() => { setshow_modal(false), handle_history_modal() }}
                keyboard={false}>
                <Modal.Header className='p-0 shadow' closeButton>
                    <Modal.Title className=' p-3 shadow'><h4 className=' text-center text-light' style={{ fontWeight: '500', fontSize: '20px' }}>Profile History</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='mx-0 d-flex align-items-center'>
                        {history_fields.map((item, idx) => {
                            return (
                                <p className={`my-0 px-4 py-2  mx-3 pointer  ${(active_field == item) ? "active_history_field" : "profile_history_status"}`} onClick={() => { setactive_field(item) }}>{dd_value(item)}</p>
                            )
                        })}
                    </div>
                    <div className='profile_edit_scroll'>
                        {(active_field == "flag") &&
                            <>{loader ? loader_function() :
                                <div >
                                    {history_datas.flag_history && history_datas.flag_history.length > 0 ?
                                        <>
                                            {history_datas.flag_history.map((flag_item, idx) => {
                                                return (
                                                    <div className='profile_history_content px-2 py-4 mx-2 my-3 mt-5' key={idx}>
                                                    <div className='row mx-0 d-flex align-items-center justify-content-between'>
                                                        <div className='col-lg-8'>
                                                            <p className='my-0 ml-3 text-white'>Reason</p>
                                                            <p className='my-0 ml-3 mt-2 text-white font_weight_600'>{ flag_item.flagging_reason &&flag_item.flagging_reason .length>0  ? array_to_string(flag_item.flagging_reason):"NA"}</p>
                                                        </div>
                                                        <div className='col-lg-4 d-flex justify-content-end'>
                                                            <div className={(flag_item.unflagged_by) ? "" : 
                                                                flag_item.flag_status == "60_customer_onboarded" ?"profile_enable_btn" :'yellow_background'}>

                                                                {flag_item.unflagged_by ? <p className='m-0 text-white profile_disab_btn p-2'>Unflagged</p> :

                                                                    flag_item.flag_status == "60_customer_onboarded" ?
                                                                        <p className='m-0 text-white profile_enable_btn p-2'>Resolved</p> :
                                                                        <p className='my-0 mx-3  text-white font_weight_600'> {(parseInt(flag_item.flag_status) <= 41) ? "Pending RM Action" : flag_item.flag_status == "50_pending_audit" ? "Pending Auditor Verification" : flag_item.flag_status ?dd_value(flag_item.flag_status,'status'):"N/A"}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className='row mx-0 d-flex align-items-center jusify-content-center'>
                                                            {flag_fields.map((item, idx) => {
                                                                 if ((item.key === "rekyced_on" || item.key == "rekyced_by") && parseInt(flag_item["flag_status"]) <= 41 && flag_item.unflagged_by) {
                                                                    return "";
                                                                  }
                                                                return (
                                                                    <>
                                                                        { flag_item &&flag_item[item.key] ?
                                                                            <div className='col mt-5' key={idx}>
                                                                                <p className='m-0 text-white' style={{ fontSize: "12px" }}>{dd_value(item.label)}</p>
                                                                                <p className='m-0 text-white font_weight_600' style={{ fontSize: "13px" }}>{flag_item[item.key] ?
                                                                                    (item.key == "flagged_date" || item.key == "verified_on" || item.key == "unflagged_on") ? moment(flag_item[item.key]).format('DD MMM YYYY') :
                                                                                    <>{flag_item[item.key]} </> : "-"}
                                                                                </p>
                                                                                {item.key == "initiated_by" && flag_item.role_code &&
                                                                                    <p className='m-0 text-white ' style={{ fontSize: "10px" }}>({dd_value(flag_item.role_code, 'role_code')} )</p>
                                                                                }
                                                                                {
                                                                                    item.key == "unflagged_by" && flag_item.un_flagged_by_role_code &&
                                                                                    <p className='m-0 text-white ' style={{ fontSize: "10px" }}>({dd_value(flag_item.un_flagged_by_role_code, 'role_code')} )</p>
                                                                                }

                                                                            </div> : ""
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                    </div>
                                                )
                                            })

                                            }
                                        </>
                                        : <p className='my-4 text-center '> NO DATA</p>

                                    }
                                </div>
                            }</>
                        }

                        {(active_field == "watchlist") &&
                            <>{loader ? loader_function() :

                                <div>
                                    {(history_datas.watchlist_history && history_datas.watchlist_history.length > 0) ?
                                        <>
                                            {history_datas.watchlist_history.map((item, idx) => {
                                                return (
                                                    <div className='profile_history_content px-2 py-4 mx-2 my-3 mt-5'>
                                                        <div className='row mx-0 d-flex align-items-center '>
                                                            <div className='col-lg-12 mb-3'>
                                                                <p className='my-0 ml-3 text-white' style={{fontSize:"12px"}} >Reason</p>
                                                                <p className='my-0 ml-3 mt-2 text-white font_weight_600' style={{fontSize:"13px"}}>{array_to_string(item.reason)}</p>
                                                            </div>
                                                            {item.initiated_by &&
                                                                <div className='col-lg-3'>
                                                                    <p className='my-0 ml-3 text-white' style={{fontSize:"12px"}}>Initiated by</p>
                                                                    <p className='my-0 ml-3 mt-2 text-white font_weight_600' style={{fontSize:"13px"}}>{item.initiated_by}</p>
                                                                    {item.role_code &&
                                                                        <p className='my-0 ml-3 mt-2 text-white' style={{ fontSize: "10px" }}>({dd_value(item.role_code, 'role_code')} )</p>
                                                                    }

                                                                </div>}
                                                            {item.initiated_on &&
                                                                <div className='col-lg-3'>
                                                                    <p className='my-0 ml-3 text-white' style={{fontSize:"12px"}}>Initiated on</p>
                                                                    <p className='my-0 ml-3 mt-2 text-white font_weight_600' style={{fontSize:"13px"}}>{moment(item.initiated_on).format("DD MMM YYYY")}</p>

                                                                </div>
                                                            }
                                                            {item.released_on &&
                                                                <div className='col-lg-3'>
                                                                    <p className='my-0 ml-3 text-white' style={{fontSize:"12px"}}>Un-watched on</p>
                                                                    <p className='my-0 ml-3 mt-2 text-white font_weight_600' style={{fontSize:"13px"}}>{moment(item.released_on).format("DD MMM YYYY")}</p>

                                                                </div>
                                                            }
                                                            {item.late_loans.length > 0 && (item.role_code == "rm_support") &&
                                                                <div className='col-lg-3'>
                                                                    <p className='my-0 ml-3 text-white' style={{fontSize:"12px"}}>Total late repayment</p>
                                                                    <div className='d-flex align-items-center '>
                                                                        <p className={`my-0 ml-3 mt-2 text-white font_weight_600 $ `}>{item.late_loans.length} </p>

                                                                        <span className='my-0 ml-3 mt-2 link_text pointer' onClick={() => show_all_laterepayments(idx)}>{item.late_loans.length > 0 ? "details" : ""}</span>
                                                                    </div>

                                                                </div>
                                                            }


                                                        </div>

                                                        {late_repayments[idx] &&
                                                            <div className='row col-12 mx-0 d-flex align-items-center justify-content-center'>
                                                                <hr className='text-white mt-5' />
                                                                <p className='text-white mb-0 mt-4 text-center font_weight_600'>Late Repayment Details</p>
                                                                <div className='col-6'>
                                                                    {getHistory(item.late_loans, idx)}

                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })}

                                        </>
                                        : <p className=' text-center mt-4'> NO DATA</p>
                                    }
                                </div>
                            }</>
                        }

                        {(active_field == "profile_edit") &&
                            <>{loader ? loader_function() :

                                <div >
                                    {((history_datas.profile_edit_history) && history_datas.profile_edit_history.length > 0) ?
                                        <>
                                            {history_datas.profile_edit_history.length > 0 && history_datas.profile_edit_history.slice().reverse().map((val, index) => {

                                                return (
                                                    <div key={index} className='profile_history_content border border-white  px-4 py-4 mx-2 my-3 mt-5' style={{ borderRadius: '10px' }}
                                                    >
                                                        <div className='d-flex flex-wrap justify-content-start align-items-center'>
                                                            {val.edited_sections.map((section, secIndex) => (
                                                                <React.Fragment key={secIndex}>
                                                                    <span className='text-white edit_history pr-2'>
                                                                        {dd_value(section, section)}
                                                                        {secIndex < val.edited_sections.length - 1 && (
                                                                            <span> | </span>
                                                                        )}
                                                                    </span>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>

                                                        <div className='py-4'>
                                                            <p className=' text-white sizem edit_field' >Edited Fields</p>
                                                            {val.edited_fields.map((field, fieldIndex) => (
                                                                <span className='text-white edit_history_subHead pr-2'>
                                                                    {field}
                                                                    {fieldIndex < val.edited_fields.length - 1 && (
                                                                        <span>, </span>
                                                                    )}
                                                                </span>

                                                            ))}
                                                        </div>
                                                        {val.edited_by &&
                                                            <div className='row py-2'>
                                                                <div className='col-sm-12 col-md-5'>
                                                                    <p className=' text-white sizem edit_field' >Edited By</p>
                                                                    <p className=' text-white edit_history_subHead' style={{ fontSize: '14px' }} >{val.edited_by} {val.edited_role && `(${dd_value(val.edited_role, val.edited_role)})`}</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-6'>
                                                                    <p className=' text-white sizem edit_field' >Edited At</p>
                                                                    <p className=' text-white edit_history_subHead' style={{ fontSize: '14px' }} >{val.edited_at ? moment(val.edited_at).format('DD MMM YYYY') : 'NA'}</p>
                                                                </div>
                                                            </div>
                                                        }

                                                        {val.verified_by &&
                                                            <div className='row py-2'>
                                                                <div className='col-sm-12 col-md-5'>
                                                                    <p className=' text-white sizem edit_field' >Verified By</p>
                                                                    <p className=' text-white edit_history_subHead' style={{ fontSize: '14px' }} > {val.verified_by} {val.verify_role && `(${dd_value(val.verify_role, val.verify_role)})`}</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-6'>
                                                                    <p className=' text-white sizem edit_field' >Verified At</p>
                                                                    <p className=' text-white edit_history_subHead' style={{ fontSize: '14px' }}>{val.verified_at ? moment(val.verified_at).format('DD MMM YYYY') : 'NA'}</p>
                                                                </div>
                                                            </div>
                                                        }



                                                    </div>
                                                )
                                            })}
                                        </> :
                                        <p className='my-3 text-white text-center'>NO DATA</p>
                                    }
                                </div>
                            }</>
                        }

                        {active_field == "status" &&
                            <>{loader ? loader_function() :
                                <>
                                    {history_datas.status_history ?
                                        <>
                                            {history_datas.status_history.map((item, idx) => {
                                                return (
                                                    <div className='profile_history_content px-2 py-4 mx-2 my-3 mt-5'>
                                                        {(convert_json_to_parse(item.data_after)).status &&
                                                    
                                                            <div className='d-flex justify-content-end'>
                                                                <p className={`my-0  text-white px-3 py-2 mx-3 ${(convert_json_to_parse(item.data_after)).status == "enabled" ? "profile_enable_btn" : "profile_disab_btn"}`}>{dd_value((convert_json_to_parse(item.data_after)).status , 'status')}</p>
                                                            </div>
                                                        }

                                                        <div className='row mx-0 d-flex align-items-center justify-content-between'>
                                                            <div className='col-lg-3'>
                                                                <p className='my-0 ml-3 text-white'>Reason</p>
                                                               
                                                                <p className='my-0 ml-3 mt-2 text-white font_weight_600'>{get_reason_from_json(item.data_after)}</p>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <p className='my-0 ml-3 text-white'>Updated on</p>
                                                                <p className='my-0 ml-3 mt-2 text-white font_weight_600'> {moment(item.created_at).format("DD MMM YYYY hh:mm a")} </p>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <p className='my-0 ml-3 text-white'>Initiated by</p>
                                                                <p className='my-0 ml-3 mt-2 text-white font_weight_600'>{item.initiated_by}</p>

                                                            </div>
                                                            {item.remarks &&
                                                                <div className='col-lg-3'>
                                                                    <p className='my-0 ml-3 text-white'>Remarks</p>
                                                                    <p className='my-0 ml-3 mt-2 text-white font_weight_600'>{item.remarks} </p>



                                                                </div>}


                                                        </div>



                                                    </div>
                                                )
                                            })}

                                        </>
                                        : <p className='my-3 text-center '> NO DATA</p>
                                    }
                                </>}
                            </>
                        }
   {(active_field === "ReKYC") && (
    <>
        {loader ? loader_function() : (
            <div>
                {history_datas.ReKYC_history && history_datas.ReKYC_history.length > 0 ? (
                    <>
                        {history_datas.ReKYC_history
                        // Sort the array by created_at in descending order
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((item, idx) => {
                            const showPendingRMAction = item.status == '40_pending_kyc' || item.status =='41_kyc_inprogress';

                            const showPendingAuditorVerification = item.status == '50_pending_audit' || item.status == '53_pending_verification';

                            const showVerified = item.status === '60_customer_onboarded';

                            const renderField = (label, value, formatter = (v) => v) => {
                                const isRemarks = label === "Remarks";  
                                  return value && (
                                   <div className={`${isRemarks ? 'col-lg-10 col-md-8' : 'col-lg-2 col-md-4'} mb-4`}>
                                        <p className='my-0 text-white'>{label}</p>
                                        <p className='my-0 mt-2 text-white font_weight_600'>{formatter(value)}</p>
                                    </div>
                                );
                            };
                            

                            return (
                                <div className='profile_history_content px-2 py-4 mx-2 my-3 mt-5' key={idx}>
                                    <div className='d-flex justify-content-end'>
                                        {/* Status Indicator at Top-Left */}
                                        <div className="d-flex justify-content-start">
                                            {showPendingRMAction && (
                                                <p className={`my-0 text-white px-3 py-2 mx-3 profile_enable_btn`}   style={{ backgroundColor: '#5A4B00', border: '1px solid #FFEA7A', fontSize: '1rem' }}>Pending RM Action</p>
                                            )}
                                            {showPendingAuditorVerification && (
                                                <p className={`my-0 text-white px-3 py-2 mx-3 profile_enable_btn`}   style={{ backgroundColor: '#5A4B00', border: '1px solid #FFEA7A', fontSize: '1rem' }}>Pending Auditor Verification</p>
                                            )}
                                            {showVerified && (
                                                <p className={`my-0 text-white px-3 py-2 mx-3 profile_enable_btn`} style={{ fontSize: '1rem' }}>Verified</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* First Row - Conditional Rendering based on Pending RM Action */}
                                    <div className='row mx-0 d-flex align-items-start mt-3'>
                                      
                                            <>
                                               {renderField("Initiated by", item.created_by, (value) => {
                                                    const regex = /^(.*?)\s?\((.*?)\)$/; 
                                                    const match = value.match(regex);
                                                    
                                                    const name = match ? match[1] : value;  
                                                    const role = match ? match[2] : "";    
                                                    return (
                                                        <>
                                                        <span style={{ fontWeight: 600 }}>{name}</span> 
                                                        {role && <><br /><span style={{ fontSize: '0.85rem', fontWeight: 'normal' }}> ({role})</span></>}
                                                        </>
                                                    );
                                                    })}



                                                {renderField("Initiated on", item.created_at, (date) => moment(date).format("DD MMM YYYY"))}
                                                {item.rm_kyc_end_date && renderField("Re-KYCed by", item.flow_rel_mgr_id)}
                                                {renderField("Re-KYCed on", item.rm_kyc_end_date, (date) => moment(date).format("DD MMM YYYY"))}
                                                {renderField("Verified by", item.audited_by)}
                                                {renderField("Verified on", item.audit_kyc_end_date, (date) => moment(date).format("DD MMM YYYY"))}
                                                
                                            </>
                                        {/* )} */}
                                    </div>

                                    {/* Reason and Remarks */}
                                    <div className="row mx-0 d-flex align-items-start mt-3">
                                        {renderField("Reason", item.kyc_reason, (reason) => dd_value(reason, "rnc_flag_reason"))}
                                        {renderField("Remarks", item.remarks)}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <p className='my-3 text-center'>NO DATA</p>
                )}
            </div>
        )}
    </>
)}


{/*</div>*/}
                        
                    </div>
                    
                </Modal.Body>
            </Modal>
        </div>
        
    );
};

export default withRouter(CustomerProfileHistory);