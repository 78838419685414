import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import DateRange from '../../common/component/DateRange';
import '../../../styles/flow.css';
import {getReport} from '../../../actions/report_actions';
import {dd_value} from '../../../actions/common_actions';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import {rt_algn, lbl_amt, format_date, make_readable, getValueFromLangJson, set_id_for_rows} from '../../../helpers/common_helper';
import {Config, get} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
import $, { event } from 'jquery';
import {Button } from 'react-bootstrap';
import Select from "react-select";
import { BasicReactTable } from '../../../helpers/react_table';
import { Link } from 'react-router-dom';
import withRouter from '../../../Router/Withrouter';
import ExportCsv from '../../common/component/ExportCsv';
import { Box } from '@mui/material';
import DataGridTable from '../../CommonComponent/table';





class CommonReportContainer extends FlowContainer {

state = {
  rows : null,
  headers: null,
  date_range: {},
  account : null,
  tableExists: false,
  csv:null,
  loader:false,
  row_data:[],
  column_data:[],
  page: { page: 0, pageSize: 10 },

}

get_header(header_arr){
  const report_header =  header_arr.map((header,idx) => {
                return (
                          <th key={idx}>{header}</th>
                      );
                      });
  report_header.unshift(<th>No</th>)
  return report_header;
}

handleFilter(event){
  let ids = event.map((e)=>e.value);
  this.setState({flow_rel_mgr_id:ids})
}
  

format_metric(value, unit){
  
    if(unit == "fa_id"){
        value = <><Link to={`/fa/view/${value}`} target="_blank">{value}</Link></>
    }
   else if(unit == "%") 
   {
      value = Number(value).toFixed(2) + " %"
   }
   else if (unit == "days")
   {
      value = Number(value).toFixed(0) + " days"
   }
   else if (unit == get('market').currency_code ||unit == "USD")
   {
      return lbl_amt(Math.round(value), unit)
      //value = Number(value).toFixed(0)
   }
   else if(unit == "status") 
   {
      value = dd_value(value);
   }else if(unit == "date"){
      value = format_date(value);
   }else if(Array.isArray(value)){
      value = value.map((item,idx)=> (  
        <div key={idx}>
        <span style={{color:"white"}}>  
          {item}  
        </span><br/>  
        </div>
      ))
  }
 
   
   
    return value;  

}


    get_rows(records_arr, units = "")
    { 
     console.log('records_arr', records_arr)
          const report_rows = records_arr.map((record,i)=>
            {
                const row = record?.map((data, i) => <td className='text-center'>{this.format_metric(data, units[i])}</td>);
                return (
                    <tr key={i}>
                        <td>{i+1}</td>
                        {row}
                    </tr>
                );
          });
          
         return report_rows;
    }

  

  componentDidMount(){
    let acc_prvdr_code = get('acc_prvdr_code');
    if(this.props.page_render){
      this.get_report()
    }
    if(this.props.with_date_range == "false"){

          this.get_report();
    }
    // if(get('acc_prvdr_code') != null){
    //   if(this.props.with_date_range == "false"){

    //     this.get_report();
    //   }else{
        let title = this.props.report_type.replace(/_/g," ") 
        this.setState({title : title})
        
      
      // }
    // }

    this.load_flow_rel_mgr(null,"multiselect_rm_name")
    this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true} , ['acc_prvdr_code', 'name'], "acc_prvdrs");

    const lender_code = this.lender_code;
    

    const account = {lender_code, "country_code": this.country_code, to_recon : true, acc_prvdr_code,status: "enabled"}

    // if(acc_prvdr_code != null){

      
      getRefAccounts(this.req({account},true))
        .then((response) => {
          if(!response){return };
          if(response.status === "success"){
            if(response.data != "")
            {
              this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
            }     
          
          }else{
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );
    // }
  }

  get_report(){
    const export_csv_prv = Config("export_csv_prv");
    let role_code=get('role_codes');
    // if(role_code == 'operations_auditor' ){
    //     role_code=get('user_id');
    // }
    let title=this.props.report_type.replace(/_/g," ")
    let addl_csv_prv_role=Config('addl_csv_prv_role');   

    export_csv_prv.includes(role_code) || ( addl_csv_prv_role && addl_csv_prv_role[role_code] != undefined && addl_csv_prv_role[role_code].includes(title)) ? this.setState({csv:'Export as csv'}) : this.setState({csv:'Request for csv'});

   const report_type = this.props.report_type
   const country_code = this.country_code
   //const data_prvdr_code = get('data_prvdr_code')
   const date_range = this.state.date_range;
   const acc_prvdr_code = this.state.acc_prvdr_code;
   const account_id = this.state.account;
   const visit_purpose =this.state.visit_purpose; 
   const flow_rel_mgr_id = this.state.flow_rel_mgr_id ? this.state.flow_rel_mgr_id : null;
    this.setState({rows:null,headers:null,loader:true});

   let searchData=[{
    title:this.props.report_type.replace(/_/g," "),
    account_id:this.state.account ? this.state.account : null,
    visit_purpose:this.state.visit_purpose ? this.state.visit_purpose : null,
    acc_prvdr_code:this.state.acc_prvdr_code ? this.state.acc_prvdr_code : null,
    start_date:this.state.date_range.start_date ? this.state.date_range.start_date : null,
    end_date:this.state.date_range.end_date ? this.state.date_range.end_date : null}]

    getReport(this.req({ report_type, country_code, date_range, acc_prvdr_code, account_id,visit_purpose, flow_rel_mgr_id }, true))

          .then((response) => {
            this.resp(response)
            if(!response){
              return this.setState({no_data:true,loader:false})};
              if(response.status === "success"){
                console.log('first', response.data.records)
                const records_arr = response.data.records ;
               

                const units = response.data.units;
                
                const headers = this.get_header(response.data.header);
                const rows =  this.get_rows(records_arr, units);
                const pageLength = this.props.report_type == "daily_visits" ? 100 :response.data.page_length
                this.setState({row_data:this.generate_row_data(response.data.header,records_arr),headers, rows, title: response.data.title ,loader:false})
                this.setState({column_data:this.generate_column_data(response.data.header, response.data.units)})
                if(rows.length==0 || response.data.records<=0){

                  this.setState({no_data:true})
                }
                else{

                  this.setState({no_data:false})

                }
                // $('#kpi_table').removeAttr('width').dataTable({
                //     retrieve : true,
                //     searching : true,
                //     paging : true,
                //     info : false,
                //     pageLength: pageLength,
                //     dom:'Bfrtip',
                //     buttons: [
                //       {
                //         title:"",
                //         extend: 'print',
                //         customize: function (win) {
                        
                //         $(win.document.body)
                //             .prepend(
                //               '<img src="'+window.location.origin+'/logo_blue.png" alt = "FLOW" style="float:left;"/>'
                //             );
                //         }
                //       },
                //       {
                //         extend:'csv',
                //         text: this.state.csv,
                //         action: function (e, dt, button, config) {
                //           if(this.text() == "Request for csv"){
                //             make_readable(searchData)
                //           }else {
                //             $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
                //           }
                //         }
                //       }
                //     ],
                //     "order" : [],
                // });
                // this.setState({tableExists: true})


              
              }else{

                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            
          }
        
          );   
  }
  get_acc_prvdr = (event) => {
    this.setState({'acc_prvdr_code' : event.target.value});
  }

  handleSubmit = (event) => {
    let report_list=['daily_activity','daily_visits','portfolio_quality','overdue_fa_repayments']
    if(report_list.includes(this.props.report_type)){
      if(!this.state.date_range.start_date || !this.state.date_range.end_date||this.state.date_range.start_date=="Invalid date"||this.state.date_range.end_date=="Invalid date"){
        alert(getValueFromLangJson("please_fill_all_the_mandatory_fields"));
        return;
      }
      if(this.state.date_range.start_date>this.state.date_range.end_date){
        alert(getValueFromLangJson("please_enter_a_date_range_in_correct_order"))
        return;

      }
    }
      
      // if(this.state.tableExists){$('#kpi_table').DataTable().destroy();}
      this.get_report();
  
   
  }
  handleAccPrvdrCode = (event) => {
    this.setState({'acc_prvdr_code' : event.target.value});

    if(this.props.with_date_range == "false"){
      this.get_report();
    }
  }
  

  loadAccounts = (event) => {
    
    if(event.target.value == ""){
      this.setState({account: null});
    }
    
    if(event.target.value != "")
      this.setState ({account: event.target.value});
  }

  generate_row_data = (headers, rows)=>{

    if(rows?.length>0 ){
    const result = rows.map(row => {
      const obj = {};
      headers.forEach((header, index) => {
          obj[header] = row[index];
      });
      return obj;
   });
   const data = set_id_for_rows(result)
   return data
  }else {return []}
 
  

  }

   generate_column_data = (headers, units_arr) => {
    let result = headers.map((value, idx) => {
      let obj = {
        headerName: value,
        id: value,
        field: value,
        align: 'center',headerAlign: 'center',  flex: 1,
        renderCell: (cell) => {
          return <div className='text-center'>{units_arr && units_arr.length > 0 ? this.format_metric(cell.row[value], units_arr[idx]) : (value == 'FAs' && !cell.row[value] ? 0 : cell.row[value])}</div>;
        }
      };
  
      return obj;
    });
    return [
    //   {
    //   headerName: <div className='text-center'>NO</div>,
    //   id: "no",
    //   field: "no",
    //   align: 'center',headerAlign: 'center',  flex: 1,
    //   renderCell: (cell) => {
    //     return <div className='text-center'>{console.log('cell', cell)} {cell.row.index+1}</div>;
    //   }
    // }
     ...result];
  };

render(){
    const show_add_prvdr = this.state.acc_prvdrs_dd?.length>1 && this.props.report_type == "penalty_pending_report" ;
      return (
      <div className="container date-range containerTopmargin max-width no-padding">
        <h5 className = "no-margin headerTitle headerPadvert text-capitalize">{this.state.title}</h5>

        <div className='row'>
          {/* {!this.admin_roles.includes(get('role_codes')) &&
            <div className="col-md-2">
              <span>Account Provider</span><font className="text-danger">   *  </font>
              <select id="acc_prvdr_code"  value = {this.state.acc_prvdr_code} onChange={(e) => {this.handleAccPrvdrCode(e);}} className="form-control" type="text" required="required">
              {this.state.acc_prvdrs_dd}
              </select>
					  </div>
          } */}
          {
            this.props.report_type=="portfolio_quality"&&this.state.title&&<div className="col-md-10">
            <DateRange className = "mb-4" id="date_range" 
              data={this.state.date_range} 
              onSubmitClick={this.handleSubmit} 
              onComponentChange={this.handleComponentChange} 
              disable_btn = {this.state.calling_api}/>
          </div>
          }
         

            {this.props.with_date_range == "true"  && 
            <div className="col-md-9">
              <DateRange className = "mb-4" id="date_range" 
                data={this.state.date_range} 
                onSubmitClick={this.handleSubmit} 
                onComponentChange={this.handleComponentChange} 
                disable_btn = {this.state.calling_api}/>
            </div>
            } 
            <div className='col-md-1'></div>
            {this.props.report_type == "daily_visits" &&
             <div className="col-md-3 mt-5">
                <span>Visit Purpose</span>
                <Select
                   maxMenuHeight={180}
                   onChange={(e)=>this.setState({'visit_purpose':e.value})}         
                  value={this.state.select_value}
                   options={this.getMultiSelectOptions('visit_purpose')} 
                   placeholder= {"choose"}
                   styles={this.select_custom_styles()}
                   classNamePrefix="my-select"
                   />

                {/* <select id="visit_purpose "  value={this.state.visit_purpose} onChange={(e)=>this.setState({'visit_purpose':e.target.value})} className="form-control fixed_height_select p-0" required>
                    {this.getSelectOptions('visit_purpose')} 
                </select>   */}
             </div>
            } 
            {get("role_codes") == "relationship_manager" && this.props.check_rel_mgr && this.props.check_rel_mgr == "true" && 
            <div className="col-md-3 mt-5">
              <span> {getValueFromLangJson("rm_label")}</span>
							<Select onChange={(e) => {this.handleFilter(e)}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" 
							  isMulti/>
            </div>
            }


            {this.props.report_type == "daily_activity" &&
            <div className='col-md-3 mt-5'>
              <span>Accounts</span>
              {/* <select id="account" className="form-control mt-2" onChange={(e) => {this.loadAccounts(e)}} onComponentChange={this.handleComponentChange}> */}
                {/* {this.state.lender_accounts_dd}                                */}
                {/* {this.state.account} */}
                <select id="account_id" className="form-control p-0" required="required" onComponentChange={this.handleComponentChange}  onChange={(e) => {this.loadAccounts(e)}} >
                  
                      {this.state.lender_accounts_dd}                               

              </select>
            </div>
            }
        </div>
        <br/>
        {get('role_codes') == "app_support" &&  show_add_prvdr&&
          <div className='row'>
            <div className="col-3 marT-5">
							<div className="">
								<select className="form-control" id="acc_prvdr_code" onChange={(e) => {this.get_acc_prvdr(e)}}>
									<option selected value="">Account Provider</option>
									{this.state.acc_prvdrs_dd}
								</select>
							</div>
					  </div>
            <div className='col-3'>
              <Button id="searchbtn" varient="info" disabled={this.props.disable_btn} className={`btn-primary`} onClick={this.handleSubmit}>Submit</Button> 
            </div>
          </div>
        }
        <br/>

          <div id='kpi_datatable'  className={"table-responsive"}>
        {this.state.loader ?
        <>
         <div className='product_takeup_loader'></div>
         <p className='text-light text-center'>{getValueFromLangJson('loading')}...</p>
        </>
        :
          <>
          {this.state.no_data && !this.state.loader && !this.state.row_data.length>0 ?
          
          <h6 className='text-center text-light p-5 m-5'>{getValueFromLangJson('no_data_found')}</h6> :
         <>
          {( !this.state.loader && this.state.row_data  && this.state.row_data.length>0) ?  
           <> 
          
          
           {/* <BasicReactTable  row_data={this.state.row_data} column_data={this.state.column_data} default_page_size = {10} pagination={this.state.row_data.length > 9 ? true : false} csv_file={true} FName={this.props.report_type ? this.props.report_type : 'report'} global_search={true} export_csv_datas={this.state.row_data}/>  */}

           <Box display={'flex'} justifyContent={'end'}>
                    <ExportCsv
                    export_csv_datas={ this.state.row_data??[] }
                    csvFileName = {this.props.report_type?? "report"}
                    paginationMode= {"client"}
                    columns={this.state.column_data??[] }
                    />
                  </Box>

                    <DataGridTable
                            rows={this.state.row_data??[] }
                            columns={this.state.column_data??[] }
                            paginationMode="client"
                            sortingMode="client"
                            rowCount={this.state.row_data?.length  ?? 0}
                            page={this.state.page}
                            setpage={(newPage) => this.setState({ page: newPage })}
                            isLoading={undefined}
                            isError={false}
                            errorText=""
                            noOfColumnsToLoad={10}
                           
                        />  
               

           
           </>:""
          }
          </>
          }
          </>
        }
            {/* <table id="kpi_table" className="table list no-footer">
          {(this.state.no_data && !this.state.loader) ?
          <h6 className='text-center text-light p-5 m-5'>{getValueFromLangJson('no_data_found')}</h6>
          :""}
         
         
          {this.state.loader && 
           <> 
           <div className='product_takeup_loader'></div>
           <p className='text-light text-center'>{getValueFromLangJson('loading')}...</p>
           </>
          }
          {this.state.headers && this.state.rows &&
            <table id="kpi_table" className="table list no-footer">

                    <thead className={"text-info"}>
                      <tr>
                        {this.state.headers}
                      </tr> 
                    </thead>
                    <tbody>
                        {this.state.rows}
                    </tbody>

            </table> */}
            

          
          </div>
      </div>
      );
  }
}

export default withRouter(CommonReportContainer);
